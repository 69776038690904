
/* eslint-disable */

import { ref, reactive, onMounted } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  components: {
    CfInputBox,
    VForm: Form,
    CfInputRadioBox
  },
  setup() {
    const formData = reactive({ taxId: "" });
    const companyForm = ref();
    const joinForm = ref();
    const otherEmail = ref("false");
    const model = CloudFun.current?.model;
    const applyData = reactive({
      user: {
        contact_Email: "",
        contact_Tel: "",
        contact_Title: "",
        position: 4,
        email: ""
      },
      company: {
        paid_In_Capital_Amount: "0"
      }
    });
    const step = ref(1);
    const validationSchema = Yup.object().shape({
      taxId: Yup.string()
        .min(8, "請輸入正確統一編號")
        .max(8, "請輸入正確統一編號")
        .required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      contact_Tel: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Email: Yup.string().test(
        "contact_Email",
        "必要欄位",
        (value, schema) => {
          if (otherEmail.value === "true")
            return value != null && value.length > 0;
          return true;
        }
      )
    });

    const loadUserData = async () => {
      const userData = model?.dispatch("user/find");
      return userData;
    };

    const getStatus = async () => {
      const status = model?.dispatch("vendor/status");
      return status;
    };

    onMounted(async () => {
      const status = await getStatus();
      if (status.payload.isApplying) {
        step.value = 4;
      } else {
        const data = await loadUserData();
        applyData.user.email = data.payload.email;
      }
    });

    return {
      validationSchema,
      formData,
      companyForm,
      applyData,
      step,
      joinForm,
      otherEmail,
      joinValidationSchema
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    submit(data: any) {
      console.log("submit", data);
      this.$model.dispatch("vendor/findByGCIS", data).then(
        response => {
          if (response.payload) {
            this.applyData.company = response.payload;
            this.step = 2;
          } else {
            CloudFun.send("error", {
              subject: "找不到資料",
              content: "請檢查輸入統編是否正確"
            });
          }
        },
        reason => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: reason
          });
        }
      );
    },
    applySubmit() {
      const data = {
        ...this.applyData.company,
        ...this.applyData.user
      } as any;
      delete data.email;
      if (data) {
        this.$model.dispatch("vendor/apply", data).then(
          response => {
            if (response.payload.isPassed) {
              CloudFun.send("success", {
                subject: "申請送出",
                content: "成功"
              });
              this.step = 4;
            }
          },
          reason => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: reason
            });
          }
        );
      }
    }
  }
});
