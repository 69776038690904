<template>
  <div class=" ">
    <!-- BEGIN: HTML Table Data -->
    <!-- step1輸入公司統一編號 -->
    <div v-if="step === 1" class="intro-y box p-10 mt-10">
      <div class="font-bold text-2xl xl:text-3xl my-5">輸入公司統一編號</div>
      <!-- <div class="font-bold text-2xl xl:text-3xl my-5">查詢要申請加入的供應商</div> -->
      <div class="mb-10 text-base text-gray-600">
        請先輸入公司統一編號查詢公司資料。
      </div>
      <div class="mb-20">
        <VForm
          ref="companyForm"
          :validation-schema="validationSchema"
          :initial-values="formData"
          @submit="submit"
        >
          <div class=" flex items-center">
            <div class="w-full sm:w-96">
              <label class="font-medium text-lg"
                >公司統編<span class=" text-red-400">＊</span></label
              >
              <CfInputBox
                name="taxId"
                placeholder="公司統編"
                maxLength="8"
                :modelValue="formData.taxId"
                @update:modelValue="
                  val => {
                    formData.taxId = val;
                  }
                "
                :showMessage="true"
                type="text"
              ></CfInputBox>
            </div>
            <div class=" ">
              <button
                type="submit"
                class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 mt-6 ml-3"
              >
                查詢
              </button>
            </div>
          </div>
        </VForm>
      </div>
      <div class=" flex justify-end items-center">
        <div class=" flex items-center">
          <a
            href="/company"
            class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
          >
            回上一步
          </a>
        </div>
      </div>
    </div>

    <!-- step2確認資料 -->
    <div v-if="step === 2" class=" intro-y box p-10 mt-10">
      <div class="font-bold text-2xl xl:text-3xl my-5">確認公司資料</div>
      <!-- <div class="font-bold text-2xl xl:text-3xl my-5">確認供應商資料</div> -->
      <div class="mb-10 text-base text-gray-600">
        為了保障您使用本平台各功能的權益，請確實填寫以下資料，以利我方審核。
      </div>
      <div class="mb-10 border-b border-theme-5">
        <div class=" md:flex items-end mb-10">
          <div class="w-full sm:w-96">
            <label class=" font-medium text-lg">公司統編</label>
            <input
              type="text"
              class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-1"
              :value="applyData.company.taxID"
              disabled
            />

            <!-- <div class=" text-gray-600 text-base">
              {{ applyData.company.taxID }}
            </div> -->
          </div>
          <div class="w-full text-gray-600 ml-2 mb-4">
            您已經是軟協會員，已為您確認相關資料，請送出申請。
            <!-- 您不是軟協會員，請送出申請，我們將核對您的相關資料。 -->
          </div>
        </div>
      </div>

      <div class="mb-20">
        <div class="lg:grid grid-cols-2 gap-10">
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >公司名稱<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company.name }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg">公司英文名稱</label>
            <div class=" text-gray-600 text-base">
              {{ applyData.company.name_Eng }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >核准設立日期<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company.setup_Date }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >實收資本額（元）<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company.capital_Stock_Amount }}
            </div>
          </div>
          <div class=" col-span-2 mb-5">
            <label class=" font-medium text-lg"
              >公司登記地址<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company.address }}
            </div>
          </div>
          <div class=" col-span-2 mb-5">
            <label class=" font-medium text-lg">公司登記英文地址</label>
            <div class=" text-gray-600 text-base">
              {{ applyData.company.address_Eng }}
            </div>
          </div>
        </div>
      </div>

      <div class=" flex justify-end items-center">
        <div class=" flex items-center">
          <a
            href="javascript:;"
            @click="back"
            class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
          >
            回上一步
          </a>
          <a
            href="javascript:;"
            @click="step += 1"
            class="button text-lg px-10 block text-white shadow-md bg-theme-1 mr-2"
          >
            下一步
          </a>
        </div>
      </div>
    </div>

    <!-- step3確認資料 -->
    <div v-if="step === 3" class="intro-y box p-10 mt-10">
      <VForm
        ref="joinForm"
        :validation-schema="joinValidationSchema"
        :initial-values="applyData.user"
        @submit="applySubmit"
      >
        <div class="font-bold text-2xl xl:text-3xl my-5">申請加入</div>
        <div class="mb-10 text-base text-gray-600">
          為了保障您使用本平台各功能的權益，請確實填寫以下資料，以利我方審核。
        </div>

        <div class="mb-20">
          <div class="lg:grid grid-cols-2 gap-10">
            <div class="">
              <div class=" mb-5">
                <label class=" font-medium text-lg"
                  >職稱<span class=" text-red-400">＊</span></label
                >
                <CfInputBox
                  name="contact_Title"
                  placeholder="職稱"
                  :modelValue="applyData.user.contact_Title"
                  @update:modelValue="
                    val => {
                      applyData.user.contact_Title = val;
                    }
                  "
                  type="text"
                ></CfInputBox>
              </div>
              <div class=" mb-5">
                <label class=" font-medium text-lg"
                  >公司業務電話<span class=" text-red-400">＊</span></label
                >
                <div class=" flex items-center">
                  <CfInputBox
                    name="contact_Tel"
                    placeholder="公司業務電話"
                    type="text"
                    :modelValue="applyData.user.contact_Tel"
                    @update:modelValue="
                      val => {
                        applyData.user.contact_Tel = val;
                      }
                    "
                  ></CfInputBox>
                  <!-- <div class="mx-3 text-lg">#</div>
                  <div class=" w-2/5">
                    <CfInputBox
                      name="phone"
                      placeholder="分機"
                      type="text"
                    ></CfInputBox>
                  </div> -->
                </div>
              </div>
            </div>
            <div class=" mb-5">
              <div class="">
                <label class=" font-medium text-lg"
                  >預設業務信箱<span class=" text-red-400">＊</span></label
                >

                <!-- <label class="input">{{ applyData.user.email }}</label> -->
                <CfInputBox
                  name="email"
                  placeholder="asdfff@cisa.com"
                  type="email"
                  v-model="applyData.user.email"
                  :disabled="true"
                ></CfInputBox>
              </div>
              <div class="text-base mb-3">
                <CfInputRadioBox
                  id="default"
                  name="email"
                  defaultValue="false"
                  :modelValue="otherEmail"
                  @update:modelValue="
                    val => {
                      otherEmail = val;
                    }
                  "
                ></CfInputRadioBox>

                <!-- <input
                  type="radio"
                  name="email"
                  id="default"
                  value="false"
                  v-model="otherEmail"
                  class="input border mr-2"
                  checked
                /> -->
                <label class="cursor-pointer select-none" for="default">
                  設為主要聯絡信箱
                </label>
              </div>
              <div class="text-base mb-3">
                <CfInputRadioBox
                  id="customize"
                  name="email"
                  defaultValue="true"
                  :modelValue="otherEmail"
                  @update:modelValue="
                    val => {
                      otherEmail = val;
                    }
                  "
                ></CfInputRadioBox>

                <!-- <input
                  type="radio"
                  name="email"
                  id="customize"
                  value="true"
                  v-model="otherEmail"
                  class="input border mr-2"
                /> -->
                <label class="cursor-pointer select-none" for="customize">
                  新增其他信箱作為主要聯絡信箱
                </label>
                <div id="customizeInput" v-if="otherEmail === 'true'">
                  <CfInputBox
                    name="contact_Email"
                    placeholder="輸入其他信箱"
                    type="email"
                    :modelValue="applyData.user.contact_Email"
                    @update:modelValue="
                      val => {
                        applyData.user.contact_Email = val;
                      }
                    "
                  ></CfInputBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class=" flex justify-end items-center">
          <div class=" flex items-center">
            <a
              href="javascript:;"
              @click="back"
              class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
            >
              上一步
            </a>

            <button
              type="submit"
              class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 ml-3"
            >
              送出申請
            </button>
            <!-- <a
              href="javascript:;"
              @click="enterCompanyData()"
              class="button text-lg px-10 block text-white shadow-md bg-theme-1 mr-2"
            >
              
            </a> -->
          </div>
        </div>
      </VForm>
    </div>

    <!-- step4 資料送出-->
    <div v-if="step === 4" class="intro-y box p-10 mt-10 h-96 content-center">
      <div class="item-center w-full h-full text-center">
        <div class="text-2xl">您的申請已完成</div>
        <div class="text-lg">平台正在為您開通功能</div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  components: {
    CfInputBox,
    VForm: Form,
    CfInputRadioBox
  },
  setup() {
    const formData = reactive({ taxId: "" });
    const companyForm = ref();
    const joinForm = ref();
    const otherEmail = ref("false");
    const model = CloudFun.current?.model;
    const applyData = reactive({
      user: {
        contact_Email: "",
        contact_Tel: "",
        contact_Title: "",
        position: 4,
        email: ""
      },
      company: {
        paid_In_Capital_Amount: "0"
      }
    });
    const step = ref(1);
    const validationSchema = Yup.object().shape({
      taxId: Yup.string()
        .min(8, "請輸入正確統一編號")
        .max(8, "請輸入正確統一編號")
        .required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      contact_Tel: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Email: Yup.string().test(
        "contact_Email",
        "必要欄位",
        (value, schema) => {
          if (otherEmail.value === "true")
            return value != null && value.length > 0;
          return true;
        }
      )
    });

    const loadUserData = async () => {
      const userData = model?.dispatch("user/find");
      return userData;
    };

    const getStatus = async () => {
      const status = model?.dispatch("vendor/status");
      return status;
    };

    onMounted(async () => {
      const status = await getStatus();
      if (status.payload.isApplying) {
        step.value = 4;
      } else {
        const data = await loadUserData();
        applyData.user.email = data.payload.email;
      }
    });

    return {
      validationSchema,
      formData,
      companyForm,
      applyData,
      step,
      joinForm,
      otherEmail,
      joinValidationSchema
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    submit(data: any) {
      console.log("submit", data);
      this.$model.dispatch("vendor/findByGCIS", data).then(
        response => {
          if (response.payload) {
            this.applyData.company = response.payload;
            this.step = 2;
          } else {
            CloudFun.send("error", {
              subject: "找不到資料",
              content: "請檢查輸入統編是否正確"
            });
          }
        },
        reason => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: reason
          });
        }
      );
    },
    applySubmit() {
      const data = {
        ...this.applyData.company,
        ...this.applyData.user
      } as any;
      delete data.email;
      if (data) {
        this.$model.dispatch("vendor/apply", data).then(
          response => {
            if (response.payload.isPassed) {
              CloudFun.send("success", {
                subject: "申請送出",
                content: "成功"
              });
              this.step = 4;
            }
          },
          reason => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: reason
            });
          }
        );
      }
    }
  }
});
</script>
