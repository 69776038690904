<template>
  <input
    :id="id"
    :name="name"
    type="radio"
    :value="defaultValue"
    v-model="value"
    class="input border mr-2"
    :class="{
      'border-red-500 bg-red-50': !!errorMessage,
      success: meta.valid
    }"
    @input="handleChange"
    @blur="handleBlur"
    :disabled="disabled"
  />
  <template v-if="showMessage">
    <div class="mt-1 ml-3 text-xs text-red-400" v-show="errorMessage">
      {{ errorMessage }}
    </div>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from "vue";
import { useField } from "vee-validate";
export default defineComponent({
  props: {
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number, Array] as PropType<any>,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    showMessage: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const name = toRef(props, "name");

    const value = computed<any>({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });

    const { errorMessage, handleBlur, handleChange, meta } = useField(
      name,
      undefined,
      { initialValue: props.modelValue }
    );

    return {
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      value
    };
  }
});
</script>

<style scoped></style>
